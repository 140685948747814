<template>
    <Modal ref="modal"
           :title="modalTitle"
           :hideFooter="true"
           :large="true">


        <template v-slot:body>

            <div class="card-body">
                <div class="row">



                    <div v-if="task.customer"
                         class="mb-3">

                        <div v-for="contact in task.customer.contacts"
                             style="display:inline-block;margin-left:20px;">
                            <img class='eventTooltipContactImage'
                                 :src="$handlers.customerHandler.getContactImage(contact)">
                            {{ contact.firstName }}
                            {{ contact.lastName }}
                        </div>



                        <span style="color: rgb(171, 140, 228); cursor: pointer;"
                              @click="$root.$emit('findCustomer', setCustomerAndEvent)">
                            החלף לקוח
                        </span>


                        <br>
                    </div>

                    <div v-else>

                        <span style="color: rgb(171, 140, 228); cursor: pointer;"
                              @click="$root.$emit('findCustomer', setCustomerAndEvent)">
                            הוסף לקוח
                        </span>

                    </div>




                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label">
                                {{ $t('taskTypes') }}
                                <span style="color:#ab8ce4;cursor:pointer"
                                      @click="$refs.QuickEditTaskTypes.show()">
                                    ניהול מהיר
                                </span>
                            </label>

                            <QuickEdit ref="QuickEditTaskTypes"
                                       :title="$t('QuickEditTaskTypes')"
                                       listName="taskTypes"
                                       controller="task-types">
                            </QuickEdit>
                            <Multiselect selectedLabel=""
                                         deselect-label=""
                                         label="name"
                                         :showNoOptions="false"
                                         track-by="id"
                                         placeholder=""
                                         selectLabel=""
                                         tagPlaceholder="הקישו אנטר ליצירת  סוג משימה חדש"
                                         :taggable="true"
                                         v-model="task.taskType"
                                         :options="$store.state.taskTypes.filter(x => !x.hidden)"
                                         @tag="$handlers.listsHandler.createListItem($event, 'taskTypes', 'task-types', task, 'taskType')">
                            </Multiselect>
                        </div>
                    </div>


                    <div class="col-4"
                         v-if="false">
                        <div class="mb-3">
                            <label class="form-label">
                                שיוך ללקוח / אירוע
                            </label>
                            <br>
                            <button class="btn btn-primary"
                                    @click="$root.$emit('findCustomer', setCustomerAndEvent)">
                                חיפוש
                                &nbsp;
                                <i class="fa-solid fa-magnifying-glass"></i>
                            </button>
                            <br>
                            <span>
                                {{ $globals.concatContactNames(task.customer) }}
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                {{ task.event && task.event.eventClass ? task.event && task.event.eventClass.name : ''
                                }}
                                {{ task.event && task.event.date ? $globals.niceDate(task.event.date) : '' }}

                            </span>
                        </div>
                    </div>








                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label">
                                שם
                            </label>
                            <input v-model="task.name"
                                   class=form-control>
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="mb-3">
                            <label class="form-label">
                                תאריך
                            </label>
                            <br>

                            <EventDatePicker :tabs="[1]"
                                             :placeHolder="$globals.niceDate(task.date)"
                                             ref="datePicker"
                                             @setTime="task.time = $event"
                                             @singleDate="task.date = $event"></EventDatePicker>
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="mb-3">
                            <label class="form-label">
                                שעה
                            </label>
                            <div>


                                <Multiselect selectedLabel=""
                                             deselect-label=""
                                             placeholder=""
                                             @tag="task.time = event;"
                                             selectLabel=""
                                             tagPlaceholder=""
                                             :taggable="true"
                                             v-model="task.time"
                                             :options="$store.state.timesArray">
                                </Multiselect>




                            </div>
                        </div>
                    </div>


                    <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label">
                                {{ $t('crewMembers') }}
                                <span style="color:#ab8ce4;cursor:pointer"
                                      @click="$refs.QuickEditCrewMembers.show()">
                                    ניהול מהיר
                                </span>
                            </label>

                            <QuickEdit ref="QuickEditCrewMembers"
                                       :title="$t('QuickEditCrewMembers')"
                                       listName="crewMembers"
                                       controller="crew-members">
                            </QuickEdit>
                            <Multiselect selectedLabel=""
                                         deselect-label=""
                                         label="name"
                                         track-by="id"
                                         :multiple="true"
                                         placeholder=""
                                         selectLabel=""
                                         tagPlaceholder="הקישו אנטר ליצירת איש צוות חדש"
                                         :taggable="true"
                                         v-model="task.crewMembers"
                                         :options="$store.state.crewMembers.filter(x => !x.hidden)"
                                         @tag="$handlers.listsHandler.createListItem($event, 'crewMembers', 'crew-members', task, 'crewMembers', true)">
                            </Multiselect>
                        </div>
                    </div>


                    <div class="col-3">
                        <div class="mb-3">
                            <label class="form-label">
                                עדיפות
                            </label>
                            <Multiselect v-model="task.priority"
                                         :options="['נמוך', 'בינוני', 'גבוה']"
                                         placeholder=""
                                         selectLabel="" />



                        </div>
                    </div>

                    <div style="position:relative;">


                        <label @click="task.showInCalendar = !task.showInCalendar"
                               style="user-select: none;cursor:pointer">
                            <span class="planup-checkbox planup-checkbox-purple"
                                  style="user-select: none;"
                                  :class="{ checked: task.showInCalendar }">
                                <i class="fa fa-check"></i>

                            </span>
                            הצג ביומן
                        </label>



                        &nbsp;
                        &nbsp;
                        &nbsp;



                        <label @click="task.recurrence.on = !task.recurrence.on"
                               style="user-select: none;cursor:pointer">
                            <span class="planup-checkbox planup-checkbox-purple"
                                  style="user-select: none;"
                                  :class="{ checked: task.recurrence.on }">
                                <i class="fa fa-check"></i>
                            </span>

                            משימה חוזרת
                        </label>





                        <div v-show="task.recurrence.on">


                            <br>


                            <div class="mb-3">

                                <label style="width:80px">
                                    חזרה כל
                                </label>




                                <input type="number"
                                       class="form-control"
                                       style="width: 80px;display:inline-block"
                                       v-model="task.recurrence.repeatDelta"
                                       value=1>

                                &nbsp;

                                <Multiselect selectedLabel=""
                                             style="width: 120px;display:inline-block;display:inline-block;position:relative;top:2px;vertical-align: bottom;"
                                             deselect-label=" "
                                             label="name"
                                             placeholder=" "
                                             selectLabel=" "
                                             v-model="task.recurrence.repeatType"
                                             :options="$store.state.taskRecurrenceRepeatTypes">
                                </Multiselect>


                            </div>





                            <div class="mb-3"
                                 style="display:none">

                                <br>

                                <div class="mb-2">
                                    חזרה בימים

                                    <span style="color:red">
                                        לא התיחסתי לימים בשבוע
                                    </span>
                                </div>

                                <span style="width:27px;display:inline-block">

                                </span>


                                <span @click="task.recurrence.days.sun = !task.recurrence.days.sun"
                                      class="dayOfWeekToggle"
                                      :class="{ chosen: task.recurrence.days.sun }">
                                    א'
                                </span>

                                &nbsp;

                                <span @click="task.recurrence.days.mon = !task.recurrence.days.mon"
                                      class="dayOfWeekToggle"
                                      :class="{ chosen: task.recurrence.days.mon }">
                                    ב'
                                </span>

                                &nbsp;

                                <span @click="task.recurrence.days.tue = !task.recurrence.days.tue"
                                      class="dayOfWeekToggle"
                                      :class="{ chosen: task.recurrence.days.tue }">
                                    ג'
                                </span>

                                &nbsp;

                                <span @click="task.recurrence.days.wed = !task.recurrence.days.wed"
                                      class="dayOfWeekToggle"
                                      :class="{ chosen: task.recurrence.days.wed }">
                                    ד'
                                </span>

                                &nbsp;

                                <span @click="task.recurrence.days.thu = !task.recurrence.days.thu"
                                      class="dayOfWeekToggle"
                                      :class="{ chosen: task.recurrence.days.thu }">
                                    ה'
                                </span>

                                &nbsp;

                                <span @click="task.recurrence.days.fri = !task.recurrence.days.fri"
                                      class="dayOfWeekToggle"
                                      :class="{ chosen: task.recurrence.days.fri }">
                                    ו'
                                </span>

                                &nbsp;

                                <span @click="task.recurrence.days.sat = !task.recurrence.days.sat"
                                      class="dayOfWeekToggle"
                                      :class="{ chosen: task.recurrence.days.sat }">
                                    ש'
                                </span>

                            </div>

                            <div>


                                סיום חזרות

                                <br>



                                <label class="planup-radio-wrapper"
                                       style="width:80px;"
                                       @click="task.recurrence.endType = 'count'">

                                    <span class="planup-radio"
                                          :class="{ checked: task.recurrence.endType == 'count' }">
                                    </span>

                                    אחרי

                                </label>


                                <input type="number"
                                       class="form-control"
                                       :class="{ disabled: task.recurrence.endType != 'count' }"
                                       style="width: 80px;display:inline-block"
                                       v-model="task.recurrence.repeatCountValue"
                                       :disabled="task.recurrence.endType != 'count'"
                                       value=1>

                                &nbsp;

                                <Multiselect selectedLabel=""
                                             style="width: 120px;display:inline-block;display:inline-block;position:relative;top:2px;vertical-align: bottom"
                                             deselect-label=" "
                                             label="name"
                                             placeholder=" "
                                             selectLabel=" "
                                             :class="{ disabled: task.recurrence.endType != 'count' }"
                                             v-model="task.recurrence.repeatCountType"
                                             :disabled="task.recurrence.endType != 'count'"
                                             :options="$store.state.taskRecurrenceRepeatCountTypes">
                                </Multiselect>

                                <br>
                                <br>



                                <!-- <label class="planup-radio-wrapper"
                                           @click="task.recurrence.endType = 'never'">

                                        <span class="planup-radio"
                                              :class="{ checked: task.recurrence.endType == 'never' }">
                                        </span>

                                        אף פעם

                                        (עד 1,000 חזרות)

                                    </label>

                                    <br>
                                    <br> -->

                                <label class="planup-radio-wrapper"
                                       style="width:80px;"
                                       @click="task.recurrence.endType = 'date'">

                                    <span class="planup-radio"
                                          :class="{ checked: task.recurrence.endType == 'date' }">
                                    </span>

                                    בתאריך

                                </label>



                                <DatePicker v-model="task.recurrence.endDate"
                                            style="width:209px"
                                            :disabled="task.recurrence.endType != 'date'"
                                            :enableTime="false"></DatePicker>



                            </div>


                            <br>



                        </div>



                    </div>


                    <br>
                    <br>


                    <div class="col-12">
                        <div class="mb-3">
                            <label class="mb-2">
                                פרטי המשימה
                            </label>
                            <textarea v-model="task.description"
                                      style="height:200px;resize: none;"
                                      class="form-control"></textarea>
                        </div>
                    </div>


                </div>

                <div class="pull-right">
                    <br>
                    <button class="btn btn-primary"
                            @click="saveTask()">
                        שמור
                    </button>
                </div>

            </div>

        </template>
    </Modal>
</template>
<script>
import EventDatePicker from '../EventDatePicker/EventDatePicker.vue';
import QuickEdit from '../QuickEdit/QuickEdit.vue';
import Multiselect from 'vue-multiselect';
import DatePicker from '../Common/DatePicker';


export default {

    name: 'TaskModal',

    data() {

        return {
            showRecurrencePopup: false,
            originalTask: null,
            task: this.$handlers.tasksHandler.generateLocalEmptyTask(),
            modalTitle: '',
        }
    },

    watch: {


        task:{
            handler: function (newVal, oldVal) {

                if (!newVal.customer || newVal!=oldVal)
                    return;              

                this.task.name = this.task.taskType?.name + ' - ' + this.$globals.concatContactNames(this.task.customer);               
            },
            deep: true
        },



    },
    methods: {

        saveTask() {

            this.$handlers.tasksHandler.saveTask(this.task);
            this.$root.$emit('showSummonModal', {
                contacts: this.task?.customer?.contacts,
                crewMembers: this.task?.crewMembers,
                title: this.task.name,
                date: this.task.date


            });
            this.$refs.modal.hide();

        },

        show(task) {

            this.originalTask = task;

            this.task = task ? deepCopy(task) : this.$handlers.tasksHandler.generateLocalEmptyTask();

            //deepcopy not make date objects
            if (this.task.date)
                this.task.date = new Date(this.task.date);

            if (this.task.description) {

                this.task.description = this.task.description.replace(/\<br\>/, '\n');
                this.task.description = this.task.description.replace(/<\/?[^>]+(>|$)/g, "");
            }



            this.modalTitle = this.task.name ? this.task.name : 'משימה חדשה';
            this.$refs.modal.show();
        },

        setCustomerAndEvent(searchResult) {



            // alert('we have a search result not a customer here');

            this.$handlers.customerHandler.getMinimalData(searchResult.customerId).then(customer => this.task.customer = customer);

            if (searchResult.eventId)
                this.task.event = { id: searchResult.eventId };

        }
    },
    components: { EventDatePicker, QuickEdit, Multiselect, DatePicker }
}
</script>