import axiosV2, { apiRequest, apiPost } from '../core/http/axios_api_v2';

export default {




    calendarTooltip(eventId) {
        return axiosV2.get(`/event/tooltip/${eventId}`).then(response => {

            let html = `
            
                <div style="text-align: center;color:rgb(119, 119, 119)">
                    ${planup.$globals.niceDate(response.data.date)}

                    ${response.data.time ? ' &nbsp; ' + response.data.time : ''}

                </div>
                <br>

                <div style="font-weight:bold;">

                    ${response.data.name}

                </div>

                <br>
            
            `;






            for (let i = 0; i < response.data?.customer?.contacts?.length; i++) {

                html += `<img class='eventTooltipContactImage' src="${planup.$handlers.customerHandler.getContactImage(response.data.customer.contacts[i])}"> ${response.data.customer.contacts[i].firstName}&nbsp;&nbsp;`

            }

            html += '<br>';
            html += '<br>';


            if (response.data?.eventClass?.name)
                html += 'סוג אירוע: ' + response.data.eventClass.name + '<br>'




            if (response.data?.place?.name)
                html += 'מקום: ' + response.data.place.name + '<br>'


            if (response.data?.estimatedNumberOfGuests)
                html += 'מספר אורחים: ' + response.data.estimatedNumberOfGuests + '<br>'


            if (response.data?.products?.length) {


                html += 'מחיר: ' + (this.sumOfProducts(response.data) * 1.17).format2Digits() + ' ש"ח<br>';

                if (response.data.mikdama) {
                    html += 'סכום מקדמה: ' + response.data.mikdama.format2Digits() + ' ש"ח<br>';
                }


                html += 'מוצרים:';

                response.data.products.forEach(product => {

                    html += product.name + ' - ' + product.count + ' יחידות';
                })

                html += '<br>';
            }


            if (response.data?.crewMembers?.length)
                html += 'צוות: ' + response.data?.crewMembers?.map(c => c.name).join(', ') + '<br>';






            return html;

        })
    },





    validateEvent(component, event) {

        // let eventErrors = {};

        // eventErrors.name = !event.name ? 'שדה חובה' : null;
        // eventErrors.date = !event.date ? 'שדה חובה' : null;
        // eventErrors.eventClass = !event.eventClass ? 'שדה חובה' : null;
        // eventErrors.estimatedNumberOfGuests = !event.estimatedNumberOfGuests ? 'שדה חובה' : null;

        // component.$set(event, 'errors', eventErrors);

    },



    mikdamaPaymentTypeOptions: [
        { name: 'כרטיס אשראי', value: 'ccard' },
        { name: 'מזומן', value: 'cash' },
        { name: "צ'ק", value: 'check' },
        { name: 'העברה בנקאית', value: 'transfer' },
        { name: 'אחר', value: 'other' },
    ],

    productPriceTypeOptions: [
        { name: 'גלובלי', value: 'global' },
        { name: 'לאדם', value: 'perperson' },
        { name: 'כמות מוגדרת', value: 'amount' },
    ],


    // helper functions
    //////////////////////

    generateLocalEmptyEvent(customer, date, time) {

        return {

            customer: customer,
            name: "",
            notes: "",
            place: null,
            time: time,
            eventClass: null,
            products: [],
            crewMembers: [],
            estimatedNumberOfGuests: null,
            otherVendors: [],
            time: planup.$store.state.businessSettings.defaultEventTime,

            // dates
            date: date?.getTime?.() ? date : null,
            specificRequestedDates: date?.getTime?.() ? [date] : [],
            requestedDateRange: {},


        }
    },




    sumOfProducts(event) {

        if (!event)
            return 0;

        let sum = parseInt(event.estimatedNumberOfGuests * event.mealCost);

        if (isNaN(sum))
            sum = 0;


        event?.products.map(p => sum += +this.getSum(p, event.estimatedNumberOfGuests));



        return !isNaN(sum) ? sum : 0;
    },

    sumOfProductsWithoutDiscount(event) {

        if (!event)
            return 0;

        let sum = parseInt(event.estimatedNumberOfGuests * event.mealCost);

        if (isNaN(sum))
            sum = 0;


        event?.products.map(p => { 
            
            if (p.discount)
                return;
            
            sum += +this.getSum(p, event.estimatedNumberOfGuests) 
        });



        return !isNaN(sum) ? sum : 0;
    },

    getSum(product, guestCount) {

        if (!product.price)
            return 0;


        if (product.priceType?.value == 'amount') {
            return !isNaN(+product.price * +product.count) ? (+product.price * +product.count) : 0;
        }


        if (product.priceType?.value == 'perperson') {
            return (+product.price * +guestCount);
        }

        return (+product.price);


    },









    eventStatusIndicatorHTML(planupSystemStatus) {




        switch (planupSystemStatus) {

            case 'priceOfferSent':
                return '<div class="btn-square btn btn-warning btn-xs">נשלחה הצעת מחיר</div>';

            case 'hazmanaSigned':
                return '<div class="btn-square btn btn-success btn-xs">הזמנה חתומה - אירוע סגור</div>';

            case 'hazmanaSent':
                return '<div class="btn-square btn btn-info btn-xs">נשלחה הזמנה</div>';

            default:
                return '<div class="btn-square btn btn-secondary btn-xs">ליד חדש</div>';

        }

    },



    prepEventFromServerToVue(eventData) {


        eventData.date = eventData.date ? new Date(eventData.date) : null;


        eventData.mikdamaPaymentType = this.mikdamaPaymentTypeOptions.find(x => x.value == eventData.mikdamaPaymentType);


    },






    buildEventSummaryTextForTimeline(event) {

        let eventSummaryText = '';

        if (event.eventClass) {
            eventSummaryText = event.eventClass.name;

            if (event.place) {
                eventSummaryText += ' ב' + event.place.name;
            }

            if (event.estimatedNumberOfGuests) {
                eventSummaryText += ', ';
            }

        }
        if (event.estimatedNumberOfGuests) {
            eventSummaryText += event.estimatedNumberOfGuests + ' אורחים';
        }

        return eventSummaryText;
    },






    // getting functions
    //////////////////////

    async getEvents() {

        return axiosV2("/event").then(

            response => response.data


        );
    },

    async getEvent(id) {

        return axiosV2(`/event/${id}`).then(

            response => response.data


        );
    },





    updateEventName(eventId, newName) {
        return apiPost('event/planupEvent', { id: eventId, name: newName });

    },




    // saving function
    //////////////////////

    // this function is good for general creation and initial changes,
    // saving THE WHOLE EVENT,
    // later we update specific things in the event no?
    async saveEvent(eventData, callback, hideSuccessAlert) {

        setNoon(eventData.date);


        if (!eventData.name)
            eventData.name = 'אירוע חדש';


        // quick clone without circular customer
        let eventDataClone;
        if (eventData.customer) {
            let customerBackup = eventData.customer;
            eventData.customer = { id: eventData.customer.id };
            eventDataClone = deepCopy(eventData);
            eventData.customer = customerBackup;
        } else {
            eventDataClone = deepCopy(eventData);
        }

        //// end quick clone



        if (eventDataClone.mikdamaPaymentType?.name) {
            eventDataClone.mikdamaPaymentType = eventDataClone.mikdamaPaymentType.value;
        }



        return apiPost('event/planupEvent', eventDataClone, hideSuccessAlert).then(
            savedEvent => {
                eventData.id = savedEvent.id;

                planup.$handlers.calendarHandler.addItemToCache(eventData.date, 'event', eventData);

                if (callback)
                    callback(savedEvent);
            }
        );
    },


    manualSetSigned(event) {
        event.planupSystemStatus = 'hazmanaSigned';
        this.updateSystemStatus(event.id, event.planupSystemStatus);
    },

    //evnt status is what user sets
    updateEventStatus(event, newStatus) {
        return apiPost(`event/updateStatus/${event.id}`, newStatus);
    },

    //system status is predefined strings that depict the event's progress
    //status here is string
    updateSystemStatus(eventId, newStatus) {
        return apiPost(`event/updateSystemStatus/${eventId}`, { planupSystemStatus: newStatus }).then(() =>
            planup.$handlers.calendarHandler.hardResetAllCalendars()
        );
    },


    //navigation functions
    // goToEvent(event) {
    //     this.$router.push(`/customer/${event.id}`)
    // }


    gotoAddNewEventToCustomer(customer, date) {
        planup.$router.push(`/customer/${customer.id}?newEvent=${date?.getTime() ? date.getTime() : ''}`)
    },


    gotoAddNewEventToCustomerFromSearchResult(searchResult, date, time) {

        let url = `/customer/${searchResult.customerId}?newEvent${date?.getTime() ? `=${date.getTime()}` : ''}`;

        if (time)
            url += `&newEventTime=${time}`;

        planup.$router.push(url)
    },


    suggestNewEventNameFromUpdatedContacts(event) {

        let contacts = event?.customer?.contacts;


        if (!contacts)
            return '';


        if (!event?.eventClass)
            return '';


        if (!contacts[0].firstName)
            return '';

        let brideName = '';
        let groomName = '';

        contacts.forEach(contact => {

            if (contact?.title?.name == 'כלה') {
                brideName = contact?.firstName;
                return;
            }

            if (contact?.title?.name == 'חתן') {
                groomName = contact?.firstName;
                return;
            }

        })



        //eli daudau wants to add the second name only if it is a wedding

        switch (true) {

            case !!brideName && !!groomName://have both bride and groom
                return 'ה' + event?.eventClass?.name + ' של ' + brideName + ' ו' + groomName;
            case !!brideName://only bride
                return 'ה' + event?.eventClass?.name + ' של ' + brideName;
            case !!groomName://only groom
                return 'ה' + event?.eventClass?.name + ' של ' + groomName;
            case contacts.length == 1://only one contact
                return 'ה' + event?.eventClass?.name + ' של ' + contacts[0].firstName;
            case contacts.length > 1://more than 1 contacts
                return 'ה' + event?.eventClass?.name + ' של ' + contacts[0].firstName + ' ו' + contacts[1].firstName;

        }

        return '';


    }



    // setPriceOfferWasSent(event) {
    //     event.priceOfferWasSent = true;
    //     return apiPost(`event/setPriceOfferWasSent/${event.id}`);
    // },




    // setOrderWasMade(event) {
    //     event.orderWasMade = true;
    //     return apiPost(`event/setOrderWasMade/${event.id}`);
    // },




    // setOrderWasSigned(event) {
    //     event.orderWasSigned = true;
    //     return apiPost(`event/setOrderWasSigned/${event.id}`);
    // },








    // statusToHtml(status) {

    //     switch (status) {


    //         case 'newlead':
    //             return '<div class="badge rounded-pill badge-secondary">ליד חדש</div>';

    //         default:
    //             return status;

    //     }

    // }







    //date functions





}