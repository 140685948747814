<template>
    <Modal ref="modal"
           :hideFooter="true"
           title="שינוי סטטוס אירוע">
        <template v-slot:body
                  v-if="event">

            האם לשנות את הסטטוס של האירוע
            <strong>{{ event.name }}</strong>?

            <br>
            <br>


            השלבים הדרושים לביצוע השינוי:
            <br>
        </template>
    </Modal>
</template>
<script>
export default {
    name: 'ChangeEventStatus',
    data() {

        return{
            event: null,
        }

    },
    methods: {
        show(event) {
            this.$refs.modal.show();
            this.event = event;
        },
        hide() {
            this.$refs.modal.hide();
        }
    }
}
</script>